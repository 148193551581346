<template>
  <div class="bg">
    <div class="filmcontainer">
    <div class="poster" v-for="item in work" :key="item">
        
        <img :src="item.poster" alt="Saturnalia Poster">
        <div class="links">
          <a v-for="link in item.links" :key="link.site" v-bind:href="link.url">{{ link.site }}</a>
        </div>
      
    </div>
  </div>
  </div>
</template>

<script>
export default {
    name: "home",
    data() {
    return {
      work: [
        {
          title: 'Saturnalia',
          poster: 'https://m.media-amazon.com/images/M/MV5BMWUxOTE2YmMtMDEzMC00ZGZhLTg4YjctODlhYTA5MjY3ZDQ0XkEyXkFqcGdeQXVyOTg4MjA2MjU@._V1_.jpg',
          links: [
            {
              site: 'IMDB',
              url: 'https://www.imdb.com/title/tt12986770/'
            },
            {
              site: 'TRAILER',
              url: 'https://www.youtube.com/watch?v=mPmAM9piwm8&t=1s'
            },
            {
              site: 'AMAZON PRIME',
              url: 'https://www.amazon.com/Saturnalia-Josh-Bzura/dp/B0B8JDTHF2/ref=sr_1_1?crid=1TH0JYFKFL7PF&keywords=saturnalia&qid=1665947489&qu=eyJxc2MiOiI1LjExIiwicXNhIjoiNC4yMyIsInFzcCI6IjQuMjIifQ%3D%3D&s=instant-video&sprefix=saturnalia%2Cinstant-video%2C140&sr=1-1'
            },
            {
              site: 'APPLE TV',
              url: 'https://tv.apple.com/us/movie/saturnalia/umc.cmc.380y5tjy136h0nu0ixh00fefv'
            }
          ]
        },
        {
          title: 'Made For This',
          poster: 'https://m.media-amazon.com/images/M/MV5BYWY1Y2Y4MjAtZjkxOS00ZjBlLTg2OTAtZjA2YTE0MjMxNTU1XkEyXkFqcGdeQXVyOTg4MjA2MjU@._V1_.jpg',
          links: [
            {
              site: 'IMDB',
              url: 'https://www.imdb.com/title/tt15067426/?ref_=adv_li_i'
            },
            {
              site: 'TRAILER',
              url: 'https://youtu.be/cflLAPFwtrA'
            }
          ]
        },
        {
          title: 'Camp Murder',
          poster: 'https://m.media-amazon.com/images/M/MV5BNmZiZGMxM2YtMWM0Mi00YmZjLTk0OGUtMDlmMjQwNGJhYzZjXkEyXkFqcGdeQXVyOTg4MjA2MjU@._V1_.jpg',
          links: [
            {
              site: 'IMDB',
              url: 'https://www.imdb.com/title/tt12986726/?ref_=adv_li_i'
            },
            {
              site: 'TRAILER',
              url: 'https://youtu.be/aLrZ0HOH5ig'
            }
          ]
        },
        {
          title: 'DALOUIS FREESTYLE VERIFIED',
          poster: 'https://img.youtube.com/vi/fi64dbHn2mQ/mqdefault.jpg',
          links: [
            {
              site: 'YOUTUBE',
              url: 'https://www.youtube.com/watch?v=fi64dbHn2mQ'
            }
          ]
        },
        
      ]
    }
  }
}
</script>

<style scoped>
.bg {
    background-color: rgb(223, 223, 223);
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.filmcontainer {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10vh;
}
.poster {
  height: 60vh;
  margin: 10vh;
  justify-self: center;
  display: grid;
  
}
img, .links {
  grid-column: 1;
  grid-row: 1;
}
img {
  top: 0;
  height: 60vh;
  position: relative;
  box-shadow: 0px 0px 3vh 1vh rgba(0,0,0,1);
  transition: all 0.5s cubic-bezier(0.040, 0.590, 0.195, 0.995);
  outline: 0px solid #1C6EA4;
}
.links {
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: -5;
  opacity: 0;
  filter: blur(0.6vh);
  transform: scale(1.5);
  transition: all 0.5s cubic-bezier(0.040, 0.590, 0.195, 0.995);
}
a {
    font-weight: bold;
    font-size: large;
    padding: 1vw;
    align-self: center;
    transition: all 1s ease;
    color: white;
    text-shadow: -2px 2px 0 #000;
}
.poster:hover img {
  transform: scale(0.9);
  box-shadow: 0px 0px 0vh 0vh rgba(0,0,0,1);
  filter: blur(0.6vh);
  opacity: 0.8;
}
.poster:hover .links {
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  z-index: 5;
}

@media (max-width: 100vh) {
  .poster {
  width: 70vw;
  height: auto;
  margin: 15vw;
  }
  img {
    width: 70vw;
    height: auto;
  }
}
</style>